<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      Accountverwaltung
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Accountverwaltung</ion-title>
    </ion-toolbar>
  </ion-header>

  <ng-container *ngIf="displayedData; else loading">
    <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <ion-list>
      <ion-searchbar #searchBar show-clear-button="always" placeholder="Suche Account"
        (ionInput)="search($event)"></ion-searchbar>

      <app-account-item *ngFor="let accountInfo of displayedData"
        [routerLink]="'details/' + accountInfo.orchesterMitgliedsId" [name]="accountInfo.orchesterMitgliedsName"
        [email]="accountInfo.email" [lastLogin]="accountInfo.lastLogin"
        [connected]="accountInfo.userId !== null"></app-account-item>
    </ion-list>
  </ng-container>

  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>

</ion-content>