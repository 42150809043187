import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { JwtModule } from '@auth0/angular-jwt';
import { Preferences } from '@capacitor/preferences';
import { TOKEN_KEY } from './authentication/services/authentication.service';
import { RefreshTokenInterceptor } from './core/interceptor/refresh-token-interceptor';
import { ErrorHandelingInterceptor } from './core/interceptor/error-handeling-interceptor';
import { CoreModule } from './core/core.module';

export async function tokenGetter() {
  return (await Preferences.get({ key: TOKEN_KEY })).value;
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, IonicModule.forRoot({
    mode: 'md'
  }), AppRoutingModule, HttpClientModule, SharedModule, CoreModule, JwtModule.forRoot({
    config: {
      tokenGetter: tokenGetter,
      allowedDomains: ["localhost:8100", "notesapp1.azurewebsites.net"]
    }
  }),],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandelingInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
