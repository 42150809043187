<ion-item [button]="true" detail="false">
  <ion-avatar aria-hidden="true" slot="start">
    <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
  </ion-avatar>
  <ion-label>
    <h2 class="name">
      <div>
        <strong>{{name}}</strong>
      </div>
      <div class="user-connected" [class.color-green]="connected"></div>
    </h2>
    <ion-text style="font-size: 14px;">E-Mail: {{email ?? 'nicht angegeben'}}</ion-text><br />
    <ion-note color="medium" class="ion-text-wrap">
      Zuletzt angemeldet: {{lastLogin ? (lastLogin | date : 'dd.MM.yyyy') : 'unbekannt'}}
    </ion-note>
  </ion-label>
  <div class="metadata-end-wrapper" slot="end">
    <ion-icon color="medium" name="chevron-forward"></ion-icon>
  </div>
</ion-item>