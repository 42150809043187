import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
