<ion-tabs>

  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="dashboard">
      <ion-icon name="cube-outline"></ion-icon>
      <ion-label>Dashboard</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="termin">
      <ion-icon name="calendar-outline"></ion-icon>
      <ion-label>Termine</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="mitglieder">
      <ion-icon name="people-outline"></ion-icon>
      <ion-label>Mitglieder</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="anwesenheit">
      <ion-icon name="bar-chart-outline"></ion-icon>
      <ion-label>Anwesenheit</ion-label>
    </ion-tab-button>
  </ion-tab-bar>

</ion-tabs>