<ion-app>
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>
        <app-side-menu></app-side-menu>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>
