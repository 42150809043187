<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      Hilfe
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Hilfe</ion-title>
    </ion-toolbar>
  </ion-header>

  <h3>Häufige Fragen</h3>

  <ion-accordion-group>
    <ion-accordion value="first">
      <ion-item slot="header" color="light">
        <ion-label class="ion-text-wrap">Was bedeuten die Farbebalken an den Terminen?</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        Die Farbe repräsentiert die Terminkategorie. Es gibt folgende Kategorien die durch die folgende Farbe repräsentiert werden: <br> <br>
        <div class="item-color">
          <div class="square auftritt"></div><div>: Auftritt/Konzert</div>
        </div>
        <div class="item-color">
          <div class="square probe"></div><div>: Probe</div>
        </div>
        <div class="item-color">
          <div class="square freizeit"></div><div>: Freizeit</div>
        </div>
        <div class="item-color">
          <div class="square reise"></div><div>: Reise</div>
        </div>
      </div>
    </ion-accordion>
    <ion-accordion value="second">
      <ion-item slot="header" color="light">
        <ion-label class="ion-text-wrap">Wie kann ich die Daten auf einer Seite aktualisieren?</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        Falls sich eine Seite der App nicht automatisch aktualisiert hat und z.B. veraltete Daten anzeigt, kann man die Seite von oben nach unten ziehen, um die Seite manuell zu aktualisieren (außer auf dieser Hilfe Seite).
      </div>
    </ion-accordion>
    <ion-accordion value="third">
      <ion-item slot="header" color="light">
        <ion-label class="ion-text-wrap">Wie funktioniert die Anwesenheitsliste?</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        Die Anwesenheitsliste bezieht sich auf alle bereits vergangenen Termine des aktuellen Jahres, welche die Terminart "Auftritt", "Probe" oder "Konzert" besitzen. <br> <br>
        Die Liste zeigt für alle nicht Vorstandsmitglieder nur die 10 ersten Einträge und den eigenen Eintrag an. D.h. wenn der eigene Eintrag nicht unter den ersten 10 Einträgen ist, wird er von anderen nicht gesehen. Die Mitglieder des Vorstands können zu jedem Zeitpunkt alle Einträge sehen.
      </div>
    </ion-accordion>
  </ion-accordion-group>

</ion-content>