<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      Accountverwaltung
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Accountverwaltung</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="data$ | async as data; else loading">

    <ion-button [routerLink]="'/tabs/account-management'" class="back-btn">
      <ion-icon slot="start" name="arrow-back"></ion-icon>
      Zurück zur Übersicht
    </ion-button>

    <ion-card>
      <ion-card-header>
        <ion-card-title>{{data.orchesterMitgliedsName}}</ion-card-title>
      </ion-card-header>
      <ion-list lines="full">
        <app-data-item label="Registrierungsschlüssel Ablaufdatum"
          [value]="data.registerKeyExpirationDate | date : 'dd.MM.yyyy hh:mm'"></app-data-item>
        <app-data-item label="E-Mail" [value]="data.email ?? 'nicht verbunden'"></app-data-item>
        <app-data-item label="Account locked"
          [value]="data.accountLocked ? 'blockiert' : 'nicht blockiert'"></app-data-item>
        <app-data-item label="Erste Anmeldung" [value]="data.firstLogin | date : 'dd.MM.yyyy hh:mm'"></app-data-item>
        <app-data-item label="Letzte Anmeldung" [value]="data.lastLogin | date : 'dd.MM.yyyy hh:mm'"></app-data-item>
      </ion-list>
    </ion-card>

    <ion-card *ngIf="data.email">
      <ion-card-header>
        <ion-card-title>Rollen von {{data.email}}</ion-card-title>
      </ion-card-header>
      <form (ngSubmit)="changeRoles(data.email)" [formGroup]="roleFormGroup">
        <ion-item>
          <ion-select label="Account Rollen" label-placement="stacked" [multiple]="true" [formControlName]="'role'">
            <ion-select-option *ngFor="let role of roles" [value]="role.value">{{role.text}}</ion-select-option>
          </ion-select>
        </ion-item>
        <div class="button-div">
          <ion-button type="submit" fill="clear"
            [disabled]="!roleFormGroup.valid || !roleFormGroup.touched">Speichern</ion-button>
        </div>
      </form>
    </ion-card>

    <ion-button fill="clear" expand="block" (click)="setOpen(true)">
      Bearbeite Account
    </ion-button>
    <ion-action-sheet [isOpen]="isActionSheetOpen" header="Bearbeite Account"
      [buttons]="data.email ? actionSheetButtons : actionSheetButtonsWithoutUser"
      (didDismiss)="setOpen(false)"></ion-action-sheet>
  </ng-container>

  <ng-template #loading>
    <app-loader *ngIf="!refreshing"></app-loader>
  </ng-template>
</ion-content>